import { createAction } from 'redux-actions';

export const setTick = createAction('ui/SET_TICK');
export const setTimestamp = createAction('ui/SET_TIMESTAMP');
export const setRewindTimestamp = createAction('ui/SET_REWIND_TIMESTAMP');
export const setRewindTimeline = createAction('ui/SET_REWIND_TIMELINE');
export const setObseravationClicked = createAction('ui/SET_OBSERVATION_CLICKED');

export const selectCause = createAction('ui/SELECT_CAUSE');
export const selectConsequence = createAction('ui/SELECT_CONSEQUENCE');
export const selectPreventions = createAction('ui/SELECT_PREVENTIONS');

export const hoverCard = createAction('ui/HOVER_CARD');
export const highlightSelectedCards = createAction('ui/HIGHLIGHT_SELECTED_CARDS');
export const deselectAllHighlightedCards = createAction('ui/DESELECT_ALL_HIGHLIGHTED_CARDS');
export const cardClick = createAction('ui/CARD_CLICK');
export const consequenceClick = createAction('ui/CONSEQUENCE_CLICK');
export const toggleHelpPage = createAction('ui/TOGGLE_HELP_PAGE');
export const toggleHelpKey = createAction('ui/TOGGLE_HELP_KEY');

export const toggleEquipmentPanel = createAction('ui/TOGGLE_EQUIPMENT_PANEL');
