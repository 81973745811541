import React from 'react';
import styled from '@emotion/styled/macro';
import { orderBy, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { AnimatePresence } from 'framer-motion';

import { getTimeline } from '../../store/timeline/timelineSelectors';
import { getTimestamp } from '../../store/ui/uiSelectors';
import { modeIsRewind } from '../../store/mode/selectors';
import TimelineSegments from './TimelineSegments';

import Scrubber from '../../features/Scrubber/Scrubber';

import TimelineHeading from './TimelineHeading';
import TimelineItem from './TimelineItem';
import AnimateList from '../common/AnimateList';

import './Timeline.css';
import TimelineEquipment from './TimelineEquipment';
import AnimateTimelineList from './AnimatedTimelineList';

const StyledTimelineWrapper = styled.div`
  --group-name-width: 110px;
  background-color: var(--color-dark-shade);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  grid-template-areas: 'timeline';
  overflow-y: scroll;
`;

const StyledTimelineDecorators = styled(StyledTimelineWrapper)`
  height: var(--observation-body-height);
  position: sticky;
  top: 0px;
  z-index: 100;
  background: none;
  pointer-events: none;
`;

const Timeline = ({ isForEmptyState }) => {
  const timeline = useSelector(getTimeline);
  const timestamp = useSelector(getTimestamp);
  const uiRewindTimestamp = useSelector((state) => state.ui.uiRewindTimestamp);
  const isRewindMode = useSelector(modeIsRewind);
  const now = isRewindMode ? DateTime.fromISO(uiRewindTimestamp) : DateTime.fromISO(timestamp);

  const ongoing = timeline.filter((t) => t.status === 'ONGOING');
  const recent = timeline.filter((t) => t.status === 'RECENT');

  if (isForEmptyState) {
    return (
      <>
        <StyledTimelineDecorators>
          <div className="timeline-slots timeline-slots--segments">
            <div className="timeline timeline-segments">
              <TimelineSegments now={now} />
              <TimelineEquipment now={now} />
            </div>
          </div>
        </StyledTimelineDecorators>
      </>
    );
  }
  return (
    <>
      <StyledTimelineDecorators>
        <div className="timeline-slots timeline-slots--segments">
          <div className="timeline timeline-segments">
            <TimelineSegments now={now} />
            <TimelineEquipment now={now} />
          </div>
        </div>
      </StyledTimelineDecorators>
      <StyledTimelineWrapper>
        <AnimateTimelineList className={`${!isForEmptyState ? 'timeline-slots' : null}`}>
          {orderBy(
            timeline,
            ['status', (item) => item.observations[0]?.started],
            ['asc', 'desc']
          ).map(({ functionId, sensor, observations, label, limits, unit, status, state }) => (
            <TimelineItem
              key={`${functionId}-${status}-${state}`}
              timeline={observations}
              metadata={{ functionId, sensor, label, unit, status, state }}
              limits={limits}
            />
          ))}
          {ongoing.length > 0 && (
            <TimelineHeading
              key={'Ongoing'}
              count={ongoing.length}
              title={'Ongoing'}
              position={1}
            />
          )}

          {recent.length > 0 && (
            <TimelineHeading
              key={'Recent'}
              count={recent.length}
              title={'Recent'}
              position={ongoing.length + 1}
            />
          )}
        </AnimateTimelineList>
        <AnimatePresence>{isRewindMode && <Scrubber />}</AnimatePresence>
      </StyledTimelineWrapper>
    </>
  );
};

export default Timeline;
