import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { setTimestamp, setRewindTimestamp, setRewindTimeline } from '../../store/ui/uiActions';
import { receivedTimeline, receivedGraphState } from '../../store/api/actions';

import { fetchTimeline, fetchStateByTimestamp, postTimelineSearchMetrics } from '../../store/api';

import styled from '@emotion/styled/macro';
import ScrubberBrush from './ScrubberBrush';
import { getOverlappingObservationsByDates } from './utils';
import { AnimatePresence, motion } from 'framer-motion';
import useTimescale from '../../hooks/useTimescale';
import { modeIsRewind } from '../../store/mode/selectors';

import { ReactComponent as ChevronDown } from '../../svgs/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../svgs/chevron-up.svg';
import { keyframes } from '@emotion/react';
//#region Styled Components
const StyledScrubberWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  z-index: 500;
  overflow: hidden;
`;

const StyledScrubberContainer = styled(motion.div)`
  display: flex;
  background-color: var(--color-darkest);
  // grid-template-columns: 260px 1fr;
  // align-items: center;
  padding: 0.5rem 1rem 0.5rem;
  position: relative;
  max-height: 500px;
  overflow: hidden;
`;

const StyledForm = styled.form`
  margin-top: 10px;

  & input {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--color-white);
    width: 200px;
    height: 20px;
    background-color: var(--color-dark-shade);
    border: none;
    border-radius: 15px;
    padding: 15px 5px 15px 0;
  }

  & input::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
  }

  & button {
    font-weight: bold;
    color: var(--color-observations);
    padding: 0 0.5rem 0 0;
  }
`;

const StyledDate = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  font-family: Roboto, inherit;
`;

const StyledDateHeading = styled.div`
  font-size: 0.65rem;
  text-transform: uppercase;
  opacity: 0.5;
`;

const StyledBrush = styled.div`
  // This element can have no padding because it is used by the SVG to set width.
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  & .visx-brush-selection {
    rx: 5;
  }

  & .visx-brush-overlay {
    pointer-events: none;
  }
`;

const DateTieWrapper = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const pulse = keyframes`
0% {
  box-shadow: 0 0 0 0 rgba(105, 206, 143, 0.4);
}
70% {
    box-shadow: 0 0 0 5px rgba(105, 206, 143, 0);
}
100% {
    box-shadow: 0 0 0 0 rgba(105, 206, 143, 0);
}
`;

const ShowMoreLessButton = styled.button`
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  gap: 0.5rem;
  font-size: 0.65rem;
  border: 1px solid #69ce8f70;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 0 0 rgba(105, 206, 143, 0.4);
  animation: 2s ${pulse} ease-out infinite;
`;
//#endregion Styled Components

const variants = {
  visible: { opacity: 1, y: '0%' },
  hidden: { opacity: 0, y: '100%' },
};

const Scrubber = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const sessionId = useSelector((state) => state.api.sessionId);
  const { TIMELINE_REWIND, TIMELINE_WINDOW } = useSelector((state) => state.api.flags);
  const { uiRewindTimestamp, uiRewindTimeline } = useSelector((state) => state.ui);
  const visible = useSelector(modeIsRewind);
  const [showMore, setShowMore] = useState(true);
  const [dateScale, width] = useTimescale(DateTime.utc(), containerRef, {
    time: TIMELINE_REWIND,
    clamp: true,
  });

  useEffect(() => {
    const current = uiRewindTimestamp;
    const minusRewindWindow = DateTime.fromISO(uiRewindTimestamp)
      .minus(TIMELINE_REWIND)
      .toJSDate()
      .toISOString();

    const fetchData = async () => {
      const { response } = await fetchTimeline({ from: current, to: minusRewindWindow });
      dispatch(setRewindTimeline(response));
    };
    fetchData();
  }, []);

  const fetchDataByTimestamp = async (timestamp) => {
    const { response } = await fetchStateByTimestamp({ timestamp });
    await postTimelineSearchMetrics(sessionId, 'TIME_SEARCH', {
      from: DateTime.fromISO(timestamp).minus({ minutes: 30 }).toUTC().toISO(),
      to: DateTime.fromISO(timestamp).toUTC().toISO(),
    });
    return response;
  };

  const handleBrushChange = async ({ start, end }) => {
    dispatch(setRewindTimestamp(DateTime.fromJSDate(end).toUTC().toISO()));
    dispatch(setTimestamp(DateTime.fromJSDate(end).toUTC().toISO()));
    const fetchedData = await fetchDataByTimestamp(DateTime.fromJSDate(end).toUTC().toISO());
    dispatch(receivedGraphState(fetchedData));
    const observationsInBrushInterval = getOverlappingObservationsByDates(
      uiRewindTimeline,
      start,
      end
    );
    dispatch(receivedTimeline(observationsInBrushInterval));
    await postTimelineSearchMetrics(sessionId, 'BRUSH_SEARCH', {
      from: DateTime.fromJSDate(end).minus({ minutes: 30 }).toUTC().toISO(),
      to: DateTime.fromJSDate(end).toUTC().toISO(),
    });
  };

  const timeSubmitHandler = async (e) => {
    e.preventDefault();
    const end = DateTime.fromISO(uiRewindTimestamp).toJSDate();
    const start = DateTime.fromISO(uiRewindTimestamp).minus({ minutes: 30 }).toJSDate();
    const fetchedData = await fetchDataByTimestamp(
      DateTime.fromISO(uiRewindTimestamp).toUTC().toISO()
    );
    dispatch(receivedGraphState(fetchedData));
    const observationsInBrushInterval = getOverlappingObservationsByDates(
      uiRewindTimeline,
      start,
      end
    );
    dispatch(receivedTimeline(observationsInBrushInterval));
  };

  const timeInputChangeHandler = (e) => {
    const inputValue = e.target.value;
    const inputDateTime = DateTime.fromISO(inputValue);
    const now = DateTime.utc();

    if (inputDateTime > now) {
      dispatch(setRewindTimestamp(DateTime.utc().toLocal().toISO().slice(0, 16)));
      dispatch(setTimestamp(DateTime.utc().toLocal().toISO().slice(0, 16)));
    } else {
      dispatch(setRewindTimestamp(inputValue));
      dispatch(setTimestamp(inputValue));
    }
  };

  return (
    <StyledScrubberWrapper>
      <StyledScrubberContainer
        initial="hidden"
        animate={visible ? 'visible' : 'hidden'}
        exit="hidden"
        variants={variants}
        transition={{ default: { type: 'tween' } }}
      >
        <DateTieWrapper>
          <StyledDateHeading>Now viewing</StyledDateHeading>
          <StyledDate>
            {DateTime.fromISO(uiRewindTimestamp).toFormat('EEE dd. MMM, HH:mm')}
          </StyledDate>
          <StyledForm onSubmit={timeSubmitHandler}>
            <button type="submit">Go To</button>
            <input
              type="datetime-local"
              max={DateTime.utc().toLocal().toISO().slice(0, 16)}
              min={DateTime.utc().minus(TIMELINE_REWIND).toLocal().toISO().slice(0, 16)}
              onChange={timeInputChangeHandler}
              value={DateTime.fromISO(uiRewindTimestamp).toLocal().toISO().slice(0, 16)}
            />
          </StyledForm>
        </DateTieWrapper>
        <StyledBrush ref={containerRef}>
          <ScrubberBrush
            width={width}
            timeline={showMore ? uiRewindTimeline.slice(0, 10) : uiRewindTimeline.slice(0, 100)} // Yello lines on the graph
            dateScale={dateScale}
            handleBrushChange={handleBrushChange}
            brushStart={DateTime.fromISO(uiRewindTimestamp).minus({ minutes: 30 }).toJSDate()}
            brushEnd={DateTime.fromISO(uiRewindTimestamp).toJSDate()}
          />
          {uiRewindTimeline.length > 10 && (
            <ShowMoreLessButton onClick={() => setShowMore(!showMore)}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {showMore ? <ChevronUp /> : <ChevronDown />}
              </span>
              <span>show {showMore ? 'more' : 'less'}</span>
            </ShowMoreLessButton>
          )}
        </StyledBrush>
      </StyledScrubberContainer>
    </StyledScrubberWrapper>
  );
};

export default Scrubber;
