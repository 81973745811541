import { put, delay, select, take, spawn, fork } from 'redux-saga/effects';
import { setTick, setTimestamp, setRewindTimestamp } from '../ui/uiActions';
import { toggleRewind, modeChange, bootUpComplete } from './actions';
import { TOGGLE, COMPLETED, REWIND_MODE, LIVE_MODE } from './types';
import { dismissAllNotifications, receiveNotification } from '../notifications/actions';
import { modeIsLive, modeIsRewind } from './selectors';
import { getMostRecent, receivedGraphStateUpdate } from '../api/actions';
import { fetchEquipmentHistoryFromApi } from '../api/sagas';

export function* tickForApp() {
  while (true) {
    const isLive = yield select(modeIsLive);
    if (isLive) {
      yield put(setTimestamp(new Date()));
      // yield put(setTick());
    }
    yield delay(5000);
  }
}

function* watcherSwitchModes() {
  while (true) {
    yield take(toggleRewind);
    yield put(modeChange(TOGGLE));
  }
}

// on switch to rewind mode, fetch 12 hours timeline, set as state.timeline
// on switch to rewind mode, keep track of timestamp and fetch state accordingly
// <Timeline> need to filter based on Interval of Timeline 30 minute segment.
// Equipment longer segment can be gotten when rewind is toggled.
// State could be fetched based on timestamp if in rewind

function* watcherChangeModes() {
  while (true) {
    yield take(modeChange);
    const mode = yield select((state) => state.modes.mode);
    switch (mode) {
      case LIVE_MODE:
        yield delay(500);
        yield put(dismissAllNotifications());
        yield put(getMostRecent());

        break;
      case REWIND_MODE:
        yield fork(fetchEquipmentHistoryFromApi);
        yield put(setRewindTimestamp(new Date()));
        yield put(receiveNotification({ type: 'scrubbingActivated', isLive: false }));
        break;
    }
  }
}

function* watchStateUpdateInRewind() {
  while (true) {
    yield take(receivedGraphStateUpdate);
    const isRewind = yield select(modeIsRewind);

    // if (isRewind) {
    //   yield put(receiveNotification({ type: 'statusChange', isLive: false }));
    // }
  }
}

export default function* modeSagas() {
  // wait for bootUpComplete
  yield take(bootUpComplete);

  // when boot sequence is completed, change mode .
  yield put(modeChange(COMPLETED));

  // spawn tasks for ticks
  yield spawn(tickForApp);
  // ... and start watching for when the app switches mode
  yield spawn(watcherSwitchModes);
  // ... in addtition to watching for when we go live again
  yield spawn(watcherChangeModes);
  // ... watches changes to the graph state in rewind
  yield spawn(watchStateUpdateInRewind);
}
